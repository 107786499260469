<ng-container *ngIf="mobile_mode else frame_mod">
  <router-outlet name="mobile"></router-outlet>
</ng-container>

<ng-template #frame_mod>
  <app-header></app-header>
  <div class="wrapper">
    <main id="content" class="container">
      <router-outlet></router-outlet>
    </main>
  </div>
</ng-template>

<ng-template #tplAD>
  <div class="text-center" style="margin: 15px;">
    <h2 class="mat-dialog-title">1Campus Next App 已開放下載，體驗在指尖上操作的便利！</h2>
    <mat-dialog-content>
      <img src="/assets/img/ad_1campus_next.png" style="max-height: 55vh; max-width: 100%;" class="mb-3">
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>
